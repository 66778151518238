<template>
  <!-- 登录组件 -->
  <div class="my-container">
    <template v-if="!verConfig.useNewLogin">
      <van-nav-bar :title="$t('login.memberLogin')">
        <i slot="left" class="iconfont icon-jiantou" :color="!$isLinkGame ? '#ffd400' : '#fff'" @click="$router.back()"
          size="50px"></i>
      </van-nav-bar>
      <div class="new-form">
        <div class="form-item" v-for="(item, index) in formList" :key="index">
          <span>{{ $t(item.label) }}</span>
          <div class="inputs">
            <AreaPicker class="area" v-if="!index" @update:modelValue="modelValueChange" v-model="areaCode" />
            <input :type="item.type" :placeholder="$t(item.placeholder)" v-model="userinfo[item.key]" />
          </div>
        </div>
        <p class="forget-pwd" @click="goservice()">{{ $t('login.forgotPassword') }}</p>
        <button class="login-btn" :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }" @click="onSubmit">
          {{ $t('login.logIn') }}
        </button>
        <div class="link-pages-box">
          <p v-if="fromid" @click="$router.push(`/registe?id=${fromid}`)">
            {{ $t('login.registerAccount') }}
          </p>
          <p v-else @click="$router.push('/registe')"> {{ $t('login.registerAccount') }} </p>
          <!-- <p @click="handleLinkPages">ทดลองใช้ฟรี</p> -->
        </div>
      </div>
    </template>
    <Newlogin v-else />
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import { login } from '@/api/use.js'
import '../my.less'
import { downloadApp } from '@/utils/downloadUrl'
import { isAppIos } from '@/utils/unique'
import Newlogin from '@/views/login/components/newLogin.vue'
import verConfig from '@/utils/ver'
import AreaPicker from "@/components/areaPicker/index.vue";

export default {
  name: 'Login',
  components: { Newlogin,AreaPicker },
  data() {
    return {
      userinfo: {
        userphone: '',
        userpass: ''
      },
      fromid: '',
      formList: [
        {
          label: 'login.phoneNumber',
          type: 'number',
          placeholder: 'login.pleaseEnterPhoneNumber',
          key: 'userphone'
        },
        {
          label: 'login.password',
          type: 'password',
          placeholder: 'login.pleaseEnterPassword',
          key: 'userpass'
        }
      ],
      areaCode: verConfig.areaCode
    }
  },
  mounted() { },
  created() {
    if (localStorage.getItem('fromid')) {
      this.fromid = localStorage.getItem('fromid')
    }
  },
  methods: {
    modelValueChange(areaCode){
      this.areaCode = areaCode
    },
    goservice() {
      window.location.href = localStorage.getItem('serviceURL')
    },
    //   1、处理登录的函数
    async onSubmit() {
      const { userphone, userpass } = this.userinfo
      if (!(userphone && userpass)) {
        Toast({
          message: this.$t('login.pleaseEnterPhoneNumberOne'),
          position: 'bottom'
        })
      } else {
        this.$toast.loading({
          message: 'logining...',
          forbidClick: true, // 控制背景色是否可点击
          duration: 0 // 延迟时间，从0到2秒之间
        })
        try {
          const { data: { ret, data, msg } } = await login({...this.userinfo,country_code: this.areaCode})
          if (ret === 1) {
            this.$store.commit('setUser', data.token)
            this.$toast.success(msg)
            this.$router.push('/my')
          } else {
            this.$toast.fail(msg)
          }
        } catch (e) { }
      }
    },
    handleLinkPages() {
      Dialog.confirm({
        title: this.$t('login.token'),
        message:
          this.$t('login.theWebVersionDoesNotThisFunctionVersion'),
        cancelButtonText: this.$t('common.cancel'),
        confirmButtonText: this.$t('common.confirm')
      })
        .then(() => {
          if (isAppIos()) {
            this.$toast(this.$t('login.APPOnlySupportsAndroidUsersDownload'))
            return false
          }
          window.location.href =
            localStorage.getItem('appurl_android') || downloadApp
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>

<style lang="less" scoped>
.new-form {
  width: 100%;
  height: calc(100% - 92px);
  background-color: #fff;

  >.form-item {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
    position: relative;

    >span {
      font-size: 36px;
      color: #323233;
      margin-bottom: 15px;
    }
    .inputs{
      display: flex;
      align-items: center;
      .area{
        font-size: 30px;
        color: #555555;
        margin-right: 10px;
      }
    }
    input {
      // width: calc(100% - 220px);
      font-size: 36px;
      color: #323233;
      border: 0;
    }
  }

  >.form-item:nth-child(1) {
    padding-top: 50px;

    >input {
      padding-left: 70px;
    }

    >.num-absolute {
      position: absolute;
      font-size: 36px;
      color: #323233;
      bottom: 32px;
    }
  }

  >.forget-pwd {
    height: 70px;
    line-height: 70px;
    font-size: 28px;
    text-align: right;
    margin-right: 30px;
    color: #757575;
  }

  >.login-btn {
    width: 90%;
    height: 90px;
    line-height: 90px;
    font-size: 30px;
    background-color: #ff0000;
    color: #fff;
    border: 0;
    border-radius: 5px;
    margin: auto;
    display: block;
    text-align: center;
  }

  >.link-pages-box {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #757575;
    margin-top: 70px;

    >p {
      width: 100%;
      line-height: 30px;
      text-align: center;
    }

    >p:nth-child(1) {
      border-right: 1px solid #e4e4e4;
    }
  }
}
</style>
